import {stringToBoolean, stringToNumber, wrap} from "./window";
import {convertToObject} from "./utils";
import {isBoolean, isNumber} from "util";

export class Lightbox {
    element  = null;
    config   = null;
    items    = null;

    defaults = {
        container:     'body',
        itemSelector:  '.lightbox-link',
        thumbSelector: '.lightbox-thumb'
    };

    constructor(element) {
        this.element = element;
        this.config  = this.getConfig();
        this.items   = this.getItems();

        this.generateMarkup();

        Array.from(element.querySelectorAll(this.config.itemSelector)).forEach((item, index) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                this.initLighbox(index);

                return false;
            });
        });
    }

    getItems() {
        const element        = this.element,
              config         = this.config,
              lightbox_items = [];

        let item_index = 0;

        Array.from(element.querySelectorAll(config.itemSelector)).forEach(item => {
            const img_el  = item.querySelector(config.thumbSelector),
                  src     = item.getAttribute('href'),
                  msrc    = img_el.getAttribute('src'),
                  size    = item.getAttribute('data-size').split('x');


            if (src) {
                item_index++;

                item.dataset.index = item_index;
            }

            lightbox_items.push({
                'src':   src,
                'msrc':  msrc,
                'w':     parseInt(size[0], 10),
                'h':     parseInt(size[1], 10),
                'index': item_index
            });
        });

        return lightbox_items;
    }

    initLighbox(index) {
        const lightbox_cont  = this.element,
              config         = this.config,
              lightbox_items = this.items,
              pswpElement    = document.querySelectorAll('.pswp')[0];

        const lightbox_options = {
            showHideOpacity: false,
            // showAnimationDuration: false,
            // hideAnimationDuration: false,
            shareEl: false,
            getThumbBoundsFn: function (index) {
                var thumbnail = lightbox_cont.querySelectorAll(config.itemSelector + ' ' + config.thumbSelector)[index],
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect(),
                    ratio = $(thumbnail).data('ratio') ? $(thumbnail).data('ratio') : $(thumbnail).width() / $(thumbnail).height(),
                    x_mod = 0,
                    y_mod = 0;

                x_mod += ratio > 1 ? Math.round($(thumbnail).height() * ratio) - $(thumbnail).width() : 0;
                y_mod += ratio < 1 ? Math.round($(thumbnail).width() / ratio) - $(thumbnail).height() : 0;

                return {x: rect.left - x_mod / 2, y: rect.top + pageYScroll - y_mod / 2, w: rect.width + x_mod};
            }
        };

        lightbox_options.index = index;

        let lightbox_gallery = new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            lightbox_items,
            lightbox_options
        );

        lightbox_gallery.init();
    }

    generateMarkup(id) {
        if (document.querySelector('.pswp')) {
            return;
        }

        let pswpElement = `
            <div id="lightbox_gallery_${id}" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="pswp__bg"></div>
              <div class="pswp__scroll-wrap">
                <div class="pswp__container">
                  <div class="pswp__item"></div>
                  <div class="pswp__item"></div>
                  <div class="pswp__item"></div>
                </div>
                <div class="pswp__ui pswp__ui--hidden">
                  <div class="pswp__top-bar">
                    <div class="pswp__counter"></div>
                    <a class="pswp__button pswp__button--close" title="Close (Esc)"></a>
                    <a class="pswp__button pswp__button--share" title="Share"></a>
                    <a class="pswp__button pswp__button--fs" title="Toggle fullscreen"></a>
                    <a class="pswp__button pswp__button--zoom" title="Zoom in/out"></a>
                    <div class="pswp__preloader">
                      <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                          <div class="pswp__preloader__donut"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                  </div>
                  <a class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></a>
                  <a class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></a>
                  <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                  </div>
                </div>
              </div>
            </div>`;

        document.body.insertAdjacentHTML('beforeend', pswpElement);
    }

    getConfig() {
        const data = JSON.parse(JSON.stringify(this.element.dataset));

        Object.keys(data).forEach(function (key) {
            let boolean = stringToBoolean(data[key]),
                number  = stringToNumber(data[key]);

            if (data[key].toString().indexOf('{') === 0) {
                data[key] = convertToObject(data[key]);
            } else if (isNumber(number)) {
                data[key] = number;
            } else if (isBoolean(boolean)) {
                data[key] = boolean;
            }
        });

        return {...this.defaults, ...data };
    }
}
