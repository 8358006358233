export class Window {

    /**
     * The window object
     *
     * @returns {Window}
     */
    get window() {
        return window;
    }

    /**
     * The document object
     *
     * @returns {Document}
     */
    get document() {
        return window.document;
    }

    /**
     * The navigator object
     *
     * @returns {Navigator | WorkerNavigator}
     */
    get navigator() {
        return window.navigator || navigator;
    }

    /**
     * Viewport height
     *
     * @returns {number}
     */
    get height() {
        return Math.max(this.window.innerHeight || 0, this.document.documentElement.clientHeight || 0)
    }

    /**
     * Viewport width
     *
     * @returns {number}
     */
    get width() {
        return Math.max(this.window.innerWidth || 0, this.document.documentElement.clientWidth || 0)
    }

    /**
     * Viewport left
     *
     * @returns {number}
     */
    get left() {
        return +((this.window.pageXOffset || this.document.scrollLeft) - (this.document.clientLeft || 0) || 0);
    }

    /**
     * Viewport top
     *
     * @returns {number}
     */
    get top() {
        return +((this.window.pageYOffset || this.document.scrollTop) - (this.document.clientTop || 0) || 0);
    }

    /**
     * Is touch device
     *
     * @returns {boolean|*|number}
     */
    get isTouch() {
        return 'ontouchstart' in this.window || this.navigator.MaxTouchPoints || this.navigator.msMaxTouchPoints;
    }

    /**
     * Google tag manager data layer
     *
     * @returns {any[]}
     */
    get gtmDataLayer() {
        return this.window.dataLayer || [];
    }

    /**
     * Intercom instance
     *
     * @returns {any}
     */
    get intercom() {
        return this.window.Intercom;
    }

    /**
     * Check if element is in visible viewport
     *
     * @param element
     * @returns {boolean}
     * @see https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
     */
    inViewport(element) {
        const rect = element.getBoundingClientRect();

        const inside = (rect.top >= 0 && rect.top <= this.height && rect.bottom >= 0 && rect.bottom <= this.height);
        const bottom = (rect.top >= 0 && rect.top <= this.height && rect.bottom >= this.height);
        const top = (rect.top <= 0 && rect.bottom >= 0 && rect.bottom <= this.height);
        const wrap = (rect.top <= 0 && rect.bottom >= this.height);

        return inside || bottom || top || wrap;
    }

    /**
     * Check if the element on top of the viewport
     *
     * @param element
     */
    onTopOfViewport(element) {
        const rect = element.getBoundingClientRect();
        const top = (rect.top <= 0 && rect.bottom <= 0);

        return top;
    }

    /**
     * Check if element is in or on top of the
     *
     * @param element
     * @returns {boolean}
     */
    inOrOnTopOfViewport(element) {
        const inside = this.inViewport(element);
        const top = this.onTopOfViewport(element);

        return inside || top;
    }

    /**
     * Check if element is out of visible viewport
     *
     * @param element
     * @returns {boolean}
     */
    outOfViewport(element) {
        const rect = element.getBoundingClientRect();
        const out = (rect.top >= this.height && rect.bottom >= this.height);

        return out;
    }

    /**
     * Check if element is out or inside visible viewport
     *
     * @param element
     * @returns {boolean}
     */
    inOrOutOfViewport(element) {
        const inside = this.inViewport(element);
        const out = this.outOfViewport(element);

        return inside || out;
    }

    /**
     * Scroll to Y position
     *
     * @param top
     * @param callback
     * @param callbackDelay
     */
    scrollTo(top, callback = null, callbackDelay = 0) {
        this.window.scroll({
            top: top,
            left: 0,
            behavior: 'smooth'
        });

        // Force the callback to be executed after the window.scroll
        setTimeout(() => {
            if (typeof callback === 'function') {
                callback();
            }
        }, callbackDelay);
    }

    /**
     * Scroll to top
     *
     * @param callback
     * @param callbackDelay
     */
    scrollToTop(callback = null, callbackDelay = 0) {
        this.scrollTo(0, callback, callbackDelay);
    }

    /**
     * Force scroll to top or to 1px from top.
     *
     * @param callback
     * @param callbackDelay
     */
    forceScrollToTop(callback = null, callbackDelay = 0) {
        if (this.top === 1) {
            this.scrollTo(0, callback, callbackDelay);
        } else {
            this.scrollTo(1, callback, callbackDelay);
        }
    }

    /**
     * Scroll to element
     *
     * @param element
     * @param offset
     * @param callback
     * @param callbackDelay
     */
    scrollToElement(element, offset = 0, callback = null, callbackDelay = 0) {
        const rect = element.getBoundingClientRect();

        // Element top position in the viewport + current scroll position
        this.scrollTo((this.top + rect.top) - offset, callback, callbackDelay);
    }

}

/**
 *  Wrap all inner elements
 */
export function wrapAll(target, wrapper = document.createElement('div')) {
    ;[ ...target.childNodes ].forEach(child => wrapper.appendChild(child));
    target.appendChild(wrapper);
    return wrapper
}

/**
 *  Wrap element
 */
export function wrap(target, wrapper) {
    wrapper.innerHTML = target.outerHTML;

    target.parentNode.insertBefore(wrapper, target);
    target.remove();
}

export function stringToBoolean(string){
    switch(string.toLowerCase().trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return 'Not a boolean';
    }
}

export function stringToNumber(string) {
    var number = +string;

    if(isNaN(number)) {
        return string;
    } else {
        return number;
    }    
}