import {convertToObject} from "./utils";

export class Combobox {
  combobox = null;
  input    = null;
  select   = null;

  constructor(element) {
    this.combobox = $(element);
    this.input    = this.combobox.find('input');
    this.select   = this.combobox.find('select');

    this.select.bind('change', ()=> {
        const value = this.select.val() || this.select.find('option:selected').text();

        this.input.val(value);
    });
  }
}