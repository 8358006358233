import {convertToObject} from "./utils";
import {Window} from "./window";

export class Observe {
    sticky_el      = null;
    sentinel_el    = null;
    dataAttributes = {};
    defaults       = {
        sticky_class: 'sticky-active'
    };
    config         = {};
    observer       = null;

    constructor(element) {
        this.sticky_el = $(element);
        this.dataAttributes = this.sticky_el.data();
        this.config = $.extend({}, this.defaults, this.dataAttributes);

        [this.config.observeSentinel, this.sticky_el.prev(), this.sticky_el.next()].forEach(el => {
            let $el = $(el);

            if ($el !== undefined && ($el.is('.sticky-sentinel-before') || $el.is('.sticky-sentinel-after'))) {
                this.sentinel_el = $el;

                return;
            }
        });

        if (!('IntersectionObserver' in window) || !this.sentinel_el) {
            console.log('Intersection observer does not work or there is no sentinel element!');

            return;
        }

        this.init();
    }

    config() {
        return $.extend({}, this.defaults, this.dataAttributes);
    }

    init() {
        let removeInnerClasses,
            swapRules,
            swapClasses,
            swapElements;

        if (this.dataAttributes.hasOwnProperty('removeInnerClass') && this.dataAttributes.removeInnerClass) {
            removeInnerClasses = this.dataAttributes.removeInnerClass.split(' ').map((value) => {
                return '.' + value;
            });
        }

        if (this.dataAttributes.hasOwnProperty('swapInnerClass') && this.dataAttributes.swapInnerClass) {
            swapRules = convertToObject(this.dataAttributes.swapInnerClass);
            swapClasses = Object.keys(swapRules).map((value) => {
                return '.' + value;
            });
            swapElements = this.sticky_el.find(swapClasses.join(', '));
        }

        this.observer = new IntersectionObserver(elements => {
            let isIntersecting = elements[0].isIntersecting;

            if (window.pageYOffset <= elements[0].boundingClientRect.y) {
                isIntersecting = true;
            }

            if (!isIntersecting) {
                this.sticky_el.addClass(this.config.sticky_class);
            } else {
                this.sticky_el.removeClass(this.config.sticky_class);
            }

            this.addClasses(isIntersecting);
            this.removeClasses(isIntersecting);
            if (this.dataAttributes.hasOwnProperty('removeInnerClass') && this.dataAttributes.removeInnerClass) {
                this.removeInnerClasses(isIntersecting, removeInnerClasses);
            }
            if (this.dataAttributes.hasOwnProperty('swapInnerClass') && this.dataAttributes.swapInnerClass) {
                this.swapInnerClasses(isIntersecting, swapRules, swapElements);
            }
        });

        this.observer.observe(this.sentinel_el[0]);
    }

    addClasses(isIntersecting) {
        if (this.dataAttributes.hasOwnProperty('addClass')) {
            if (!isIntersecting) {
                this.sticky_el.addClass(this.dataAttributes.addClass);
            } else {
                this.sticky_el.removeClass(this.dataAttributes.addClass);
            }
        }
    }

    removeClasses(isIntersecting) {
        if (this.dataAttributes.hasOwnProperty('removeClass')) {
            if (!isIntersecting) {
                this.sticky_el.removeClass(this.dataAttributes.removeClass);
            } else {
                this.sticky_el.addClass(this.dataAttributes.removeClass);
            }
        }
    }

    removeInnerClasses(isIntersecting, classes) {
        if (!isIntersecting) {
            var $elements = this.sticky_el.find(classes.join(', '));
            $elements.each((i, elem) => {
                var $el = $(elem),
                    class_list = '';
                classes.forEach((value) => {
                    class_list += $el.is(value) ? value.replace('.', '') + ' ' : '';
                });
                $el
                    .data('removed-classes', class_list.trim())
                    .addClass('sticky-modified')
                    .removeClass(this.dataAttributes.removeInnerClass);
            });
        } else {
            this.sticky_el.find('.sticky-modified').each((i, elem) => {
                $(elem).addClass($(elem).data('removed-classes'));
            });
        }
    }
    swapInnerClasses(isIntersecting, rules, $elements) {
        if (!isIntersecting) {
            $elements.each((i, elem) => {
                var $el = $(elem);
                Object.keys(rules).forEach((key) => {
                    if ($el.hasClass(key)) {
                        $el.removeClass(key);
                        $el.addClass(rules[key]);
                    }
                });
            });
        } else {
            $elements.each((i, elem) => {
                var $el = $(elem);
                Object.keys(rules).forEach((key) => {
                    if ($el.hasClass(rules[key])) {
                        $el.removeClass(rules[key]);
                        $el.addClass(key);
                    }
                });
            });
        }
    }
}