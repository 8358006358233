export class SwiperGallery {
    gallery = null;
    defaults = {
        thumbsDirection: 'horizontal',
        thumbsSpacing: 20,
        thumbsNumber: 4,
        breakpoint: '',
        lazyLoading: false
    };
    slides = null;
    thumbs = null;
    breakpoint = null;
    sliderSwiper = null;
    thumbsSwiper = null;

    constructor(element) {
        this.gallery = $(element);
        this.slides = this.gallery.find('.tb__gallery__slide');
        this.thumbs = this.gallery.find('.tb__gallery__thumbs > [data-slide]');
        this.breakpoint = window.matchMedia(this.config().breakpoint);

        this.breakpoint.addListener(this.breakpointChecker);
        this.breakpointChecker();
    }

    config() {
        return $.extend({}, this.defaults, this.gallery.data());
    }

    createSlider() {
        let slides_html = '<div class="swiper-slides swiper-container"><div class="swiper-wrapper">';

        if (this.thumbs.length > 0) {
            $.each(this.thumbs, (index, element) => {
                if (index === 0 || !this.config().lazyLoading) {
                    slides_html += '<div class="swiper-slide">';
                    slides_html += '  <img src="' + $(element).data('slide') + '" />';
                    slides_html += '</div>';
                } else {
                    slides_html += '<div class="swiper-slide">';
                    slides_html += '  <img class="swiper-lazy" data-src="' + $(element).data('slide') + '" />';
                    slides_html += '  <div class="spinner-border" style="width: 30px; height: 30px;"></div>';
                    slides_html += '</div>';
                }
            });

            this.thumbs.wrapAll('<div class="swiper-thumbs swiper-container"><div class="swiper-wrapper"></div></div>').wrap('<div class="swiper-slide"></div>');
        } else {
            $.each(this.slides, (index, element) => {
                if (index > 0 && (this.config().lazyLoading || $(element).is('.lazyload') || $(element).find('.lazyload').length)) {
                    const $img = $(element).is('img') ? $(element) : $(element).find('img');

                    slides_html += '<div class="swiper-slide">';
                    slides_html += '  <img class="swiper-lazy" data-src="' + $img.data('src') + '" />';
                    slides_html += '  <div class="spinner-border" style="width: 30px; height: 30px;"></div>';
                    slides_html += '</div>';
                } else {
                    slides_html += '<div class="swiper-slide">';
                    slides_html += '  ' + $(element).prop("outerHTML");
                    slides_html += '</div>';
                }
            });
        }

        slides_html += '</div></div>';

        this.gallery.find('> .tb__gallery__slide, > .tb__image').hide();
        this.gallery.prepend(slides_html);

        const slides_element = this.gallery.find('.swiper-slides');
        const thumbs_element = this.gallery.find('.swiper-thumbs');

        this.sliderSwiper = new Swiper(slides_element, {
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            preloadImages: false,
            lazy: {
                preloaderClass: 'spinner-border'
            },
            keyboard: {
                enabled: true
            },
            init: false
        });

        if (thumbs_element.length > 0) {
            const self = this;

            this.thumbsSwiper = new Swiper(thumbs_element, {
                direction: this.config().thumbsDirection,
                slidesPerView: this.config().thumbsNumber,
                spaceBetween: this.config().thumbsSpacing,
                threshold: 3,
                mousewheel: true,
                init: false
            });

            this.sliderSwiper
                .on('init', function () {
                    $(this.$el).addClass('swiper-active');
                })
                .on('slideChange', () => {
                    if (this.sliderSwiper.activeIndex < this.thumbsSwiper.activeIndex || this.sliderSwiper.activeIndex > this.thumbsSwiper.activeIndex) {
                        //this.thumbsSwiper.slideTo(this.sliderSwiper.activeIndex);
                    }
                    if (this.sliderSwiper.activeIndex === this.thumbsSwiper.activeIndex) {
                        this.thumbsSwiper.slidePrev();
                    }
                    if (this.sliderSwiper.activeIndex + 1 === this.thumbsSwiper.activeIndex + this.thumbsSwiper.params.slidesPerView) {
                        this.thumbsSwiper.slideNext();
                    }
                    thumbs_element.find('.active').removeClass('active');
                    $(this.thumbsSwiper.slides[this.sliderSwiper.activeIndex]).addClass('active');
                });

            this.thumbsSwiper
                .on('init', function () {
                    $(this.slides[0]).addClass('active');
                    $(this.$el).addClass('swiper-active');
                })
                .on('tap', () => {
                    if (this.thumbsSwiper.clickedIndex !== undefined) {
                        this.sliderSwiper.slideTo(this.thumbsSwiper.clickedIndex);
                        thumbs_element.find('.active').removeClass('active');
                        $(this.thumbsSwiper.clickedSlide).addClass('active');
                    }
                    if (this.thumbsSwiper.params.slidesPerView < this.thumbsSwiper.slides.length) {
                        if (this.thumbsSwiper.clickedIndex - this.thumbsSwiper.params.slidesPerView + 1 === this.thumbsSwiper.activeIndex) {
                            this.thumbsSwiper.slideNext();
                        }
                        if (this.thumbsSwiper.clickedIndex === this.thumbsSwiper.activeIndex) {
                            this.thumbsSwiper.slidePrev();
                        }
                    }
                });

            this.thumbsSwiper.init();
        }

        this.sliderSwiper.init();
    };

    breakpointChecker() {
        if (this.breakpoint.matches === true) {
            this.createSlider();
        } else if (this.breakpoint.matches === false) {
            if (this.sliderSwiper) {
                this.sliderSwiper.destroy(true, true);
            }

            if (this.thumbsSwiper) {
                this.thumbsSwiper.destroy(true, true);
            }

            this.gallery.find('> .swiper-slides').remove();
            this.gallery.find('> .tb__gallery__slide').show();
        }
    };
}