/**
 * String to Object
 *
 * @param value
 * @returns {*}
 */
export function convertToObject(value) {
  if (value == null || typeof(value) != 'string') {
    return null;
  }

  var $process = function (value, isArray) {
    var toReturn = (isArray === true ? [] : {});
    var tmp = null;
    var _engine = {
      key: null,
      value: null,
      curlyBracket: {
        in: false,
        priority: 0
      },
      quote: {
        hasLast: false,
        type: '',
        in: false,
        priority: 0
      },
      separatorPast: false,
      toNextPoint: false,
      hadQuote: false
    };

    if (value == null || typeof(value) != 'string') {
      return value;
    }

    for (var i = 0; value[i] != undefined; i++) {
      switch (value[i]) {
        case '[':
        case '{':
          if (_engine.quote.in == true) {
            tmp += value[i];
            break;
          }
          if (_engine.toNextPoint == true) {
            tmp += value[i];
            _engine.curlyBracket.priority += 1;
            break;
          }

          tmp = '';
          _engine.toNextPoint = true;

          break;
        case '}':
        case ']':
          if (_engine.quote.in == true) {
            tmp += value[i];
            break;
          }
          if (_engine.toNextPoint == false) {
            break;
          }
          if (_engine.curlyBracket.priority != 0) {
            tmp += value[i];
            _engine.curlyBracket.priority -= 1;
            break;
          }

          _engine.toNextPoint = false;
          _engine.value = new $process(tmp, (value[i] == ']' ? true : false));
          tmp = null;

        case ':':
        case '=':
          if (_engine.toNextPoint == true || _engine.quote.in == true) {
            tmp += value[i];
            break;
          }

          _engine.hadQuote = false;
          _engine.separatorPast = true;
          _engine.key = (tmp != null ? tmp : _engine.key);
          tmp = null;
          break;
        case '\n':
        case '\r':
        case ',':
        case ';':
          if (_engine.toNextPoint == true || _engine.quote.in == true) {
            tmp += value[i];
            break;
          }

          var tmpValue = (_engine.value == null ? tmp : _engine.value);
          if (_engine.hadQuote === false && tmpValue != null) {
            try {
              tmp = JSON.parse(tmpValue);
              tmpValue = tmp;
            } catch (e) {
            }
          }
          if (isArray === true && tmpValue != null) {
            toReturn.push(tmpValue);
          } else if (isArray !== true && tmpValue != null) {
            if (_engine.key != null) {
              toReturn[_engine.key] = tmpValue;
            } else {
              toReturn = tmpValue;
            }
          }

          tmp = null;
          _engine.hadQuote = false;
          _engine.key = null;
          _engine.value = null;
          _engine.separatorPast = false;
          break;
        case '\'':
        case '"':
        case '`':
          if (_engine.toNextPoint == true || (_engine.quote.in == true && _engine.quote.type != value[i])) {
            tmp += value[i];
            break;
          }
          _engine.hadQuote = true;
          if (_engine.quote.in == false) {
            tmp = '';
            _engine.quote.in = true;
            _engine.quote.type = value[i];
            _engine.quote.priority += 1;
            break;
          }
          _engine.quote.priority -= 1;
          if (_engine.quote.priority == 0) {
            _engine.quote.hasLast = true;
            _engine.quote.in = false;
          }
          continue;
          break;
        case ' ':
        case '\t':
          if (_engine.toNextPoint == true || _engine.quote.in == true) {
            tmp += value[i];
            break;
          }
          break;
        default:
          if (_engine.quote.hasLast == true) {
            tmp += _engine.quote.type;
            _engine.quote.priority += 1;
            _engine.quote.in = true;
          }
          tmp = (tmp == null ? value[i] : tmp + value[i]);
      }
      _engine.quote.hasLast = false;
    }

    var tmpValue = (_engine.value == null ? tmp : _engine.value);
    if (_engine.hadQuote === false && tmpValue != null) {
      try {
        tmp = JSON.parse(tmpValue);
        tmpValue = tmp;
      } catch (e) {
      }
    }
    if (isArray === true && tmpValue != null) {
      toReturn.push(tmpValue);
    } else if (isArray !== true && tmpValue != null) {
      if (_engine.key != null) {
        toReturn[_engine.key] = tmpValue;
      } else {
        toReturn = tmpValue;
      }
    }

    return toReturn;
  };

  return new $process(value);
}

/**
 * Inline SVG icons
 *
 * @param e
 * @param t
 * @returns {boolean}
 */
export function includeSvgResource(file, id, revision) {
    var file     = file,
        id       = id || 'iconSvg',
        revision = revision || 1;

    if(!document.createElementNS || !document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect)
        return true;
 
    var isLocalStorage = 'localStorage' in window && window['localStorage'] !== null,
        request,
        data,
        insertIT = function() {
            document.body.insertAdjacentHTML('afterbegin', data);
        },
        insert = function() {
            if (document.body) insertIT();
            else document.addEventListener('DOMContentLoaded', insertIT);
        };
 
    if (isLocalStorage && localStorage.getItem(id + 'Rev') == revision) {
        data = localStorage.getItem(id + 'Data');

        if (data) {
            insert();
            return true;
        }
    }
 
    try {
        request = new XMLHttpRequest();
        request.open('GET', file, true);
        request.onload = function() {
            if (request.status >= 200 && request.status < 400) {
                data = request.responseText;
                insert();
                if (isLocalStorage) {
                    localStorage.setItem(id + 'Data', data);
                    localStorage.setItem(id + 'Rev', revision);
                }
            }
        }
        request.send();
    }

    catch(e){}
}

// IE polyfills

export function wrapInsideElement(selector, wrapper) {
  selector = document.querySelectorAll(selector);
  wrapper = wrapper.replace(/(^<.*\/|>$)/g, '');

  [].forEach.call(selector, function(element) {
      if (!element.querySelectorAll(wrapper).length) {
          var newChild = document.createElement(wrapper);
          
          while (element.childNodes.length) {
              newChild.appendChild(element.childNodes[0]);
          }

          element.appendChild(newChild);
      }
  });
}
