import {convertToObject} from "./utils";
import {wrapAll, wrap, stringToBoolean, stringToNumber} from "./window";
import {isBoolean, isNumber} from "util";

export class Carousel {
    element  = null;
    carousel = null;
    config   = null;

    defaults = {
        init: false,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        breakpoints: {
            650: {
                slidesPerView: 2
            },
            1000: {
                slidesPerView: 3
            }
        }
    };

    constructor(element) {
        this.element = element;
        this.config  = this.getConfig();

        const config = this.config,
            carouselWrapper = document.createElement('div'),
            carouselItems = Array.from(this.element.children);

        console.log(config);

        carouselItems.forEach(item => {
            const itemWrapper = document.createElement('div');

            itemWrapper.classList.add('swiper-slide');
            wrap(item, itemWrapper);
        });

        carouselWrapper.classList.add('swiper-wrapper');
        wrapAll(this.element, carouselWrapper);

        if (config.navigation) {
            this.insertNavigation();
        }
        if (config.pagination) {
            this.insertPagination();
        } else {
            this.element.classList.add('swiper-container-nopagination');
        }
        if (element.classList.contains('row') && !config.spaceBetween) {
            config.spaceBetween = 42;
        }

        this.carousel = new Swiper(this.element, config);

        this.addInitializedClass();

        this.carousel.init();
    }

    insertNavigation() {
        const config = this.config,
            containerClass = config.navContainerClass || 'swiper-nav';

        this.element.insertAdjacentHTML(
            'beforeend',
            `<div class="${containerClass}">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>`
        );
    }

    insertPagination() {
        this.element.insertAdjacentHTML(
            'beforeend',
            `<div class="swiper-pagination"></div>`
        );
    }

    addInitializedClass() {
        const config = this.config;

        if (config.initializedExtraClass) {
            this.element.className += ' ' + config.initializedExtraClass;
        }
    }

    getConfig() {
        const data = JSON.parse(JSON.stringify(this.element.dataset));

        Object.keys(data).forEach(function (key) {
            let boolean = stringToBoolean(data[key]),
                number  = stringToNumber(data[key]);

            if (data[key].toString().indexOf('{') === 0) {
                data[key] = convertToObject(data[key]);
            } else if (isNumber(number)) {
                data[key] = number;
            } else if (isBoolean(boolean)) {
                data[key] = boolean;
            }
        });

        return {...this.defaults, ...data };
    }
}